import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { GeolocateControl, ViewportProps, FlyToInterpolator } from 'react-map-gl';
import nearMe from '../walk/sprites/near-me.svg';
import nearMeDark from '../walk/sprites/near-me-dark.svg';
import nearMeActive from '../walk/sprites/near-me-active.svg';
import { useUpdateViewport } from '@citydna/maps';

/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme: Theme) => ({
  geolocation: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    background: 'transparent',
    borderColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 46 / 2,
    '&.mapboxgl-ctrl-group:not(:empty)': {
      boxShadow: 'none',
    },
    '& button': {
      width: 46,
      height: 46,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 46 / 2,
      backgroundColor:
        theme.palette.type === 'dark' ? theme.palette.grey[600] : theme.palette.grey[50],
      border: '1px solid',
      borderColor:
        theme.palette.type === 'dark' ? theme.palette.grey[400] : theme.palette.grey[200],
      '&:not(:disabled):hover': {
        backgroundColor:
          theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[100],
      },
    },
    '&.mapboxgl-ctrl .mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon': {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url(${theme.palette.type === 'dark' ? nearMeDark : nearMe})`,
    },
    '& button.mapboxgl-ctrl-geolocate-active .mapboxgl-ctrl-icon': {
      backgroundImage: `url(${theme.palette.type === 'dark' ? nearMeDark : nearMeActive})`,
    },
  },
}));

export const GeolocationControl = () => {
  const { setViewport } = useUpdateViewport();
  /** Smoothly transition to the geolocation when turned on for a better experience */
  const handleGeolocateViewport = ({ latitude, longitude }: ViewportProps) => {
    setViewport({
      latitude,
      longitude,
      zoom: 13,
      transitionInterpolator: new FlyToInterpolator(),
      transitionDuration: 1500,
      transitionEasing: (t: number) =>
        t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
    });
  };

  const classes = useStyles();
  return (
    <GeolocateControl
      className={classes.geolocation}
      positionOptions={{ enableHighAccuracy: true }}
      trackUserLocation={false}
      onViewportChange={handleGeolocateViewport}
    />
  );
};
