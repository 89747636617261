import React, { useMemo } from 'react';
import { useMapData } from './MapDataProvider';
import { Layer, Source } from 'react-map-gl';
import { BEFORE_ID } from './WalkRoute';
import { useTheme, darken } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { Expression } from 'mapbox-gl';

export const ADDITIONAL_SITES_SOURCE = 'walking-map-additional-sites-source';
export const ADDITIONAL_SITES = 'walking-map-additional-sites';
export const WAYPOINTS_SOURCE = 'walking-map-waypoints-source';
export const WAYPOINT_PINS = 'walking-map-waypoint-pins';
export const WAYPOINT_DOTS = 'walking-map-waypoint-dots';

export const WalkWaypointsLayers = () => {
  const theme = useTheme();
  const isWalk = useRouteMatch('/:slug/walk');

  const match = useRouteMatch<{ slug: string }>('/:_1/walk/:slug');
  const slug = match?.params.slug;

  /** Waypoint pins (for when zoomed in) */
  const waypointPins = useMemo(
    () => ({
      maxzoom: 22,
      minzoom: 13.5,
      paint: {
        'text-color':
          slug !== undefined
            ? ([
                'match',
                ['get', 'slug'],
                slug,
                theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.grey[50],
                theme.palette.grey[50],
              ] as Expression)
            : theme.palette.grey[50],
        'text-opacity': isWalk ? 1 : 0,
        'icon-opacity': isWalk ? 1 : 0,
      },
      layout: {
        'icon-allow-overlap': true,
        'icon-anchor': 'bottom' as 'bottom',
        'icon-ignore-placement': true,
        'icon-image':
          slug !== undefined
            ? ([
                'match',
                ['get', 'slug'],
                slug,
                theme.palette.type === 'dark' ? 'active-com-marker-dark' : 'active-com-marker',
                theme.palette.type === 'dark' ? 'default-com-marker-dark' : 'default-com-marker',
              ] as Expression)
            : theme.palette.type === 'dark'
            ? 'default-com-marker-dark'
            : 'default-com-marker',
        'icon-size': 0.33,
        'text-allow-overlap': true,
        'text-field': ['to-string', ['get', 'index']] as Expression,
        'text-font': ['Gotham Bold'],
        'text-ignore-placement': true,
        'text-offset': [-0.03, -1.3],
        'text-size': 16,
      },
    }),
    [theme, isWalk, slug]
  );

  const additionalSitesLayer = useMemo(
    () => ({
      paint: {
        'icon-opacity': isWalk ? 1 : 0,
      },
      layout: {
        'icon-allow-overlap': true,
        'icon-anchor': 'bottom' as 'bottom',
        'icon-ignore-placement': true,
        'icon-size': 0.33,
        'icon-image': [
          'match',
          ['get', 'slug'],
          slug,
          'active-additional-site',
          'additional-site',
        ] as Expression,

        // poiSlug !== undefined
        //   ? ([
        //       'match',
        //       ['get', 'slug'],
        //       poiSlug,
        //       theme.palette.type === 'dark' ? 'active-additional-site' : 'active-additional-site',
        //       theme.palette.type === 'dark' ? 'additional-site' : 'additional-site',
        //     ] as Expression)
        //   : theme.palette.type === 'dark'
        //   ? 'additional-site'
        //   : 'additional-site',
      },
    }),
    [isWalk, slug]
  );

  /** Waypoint dots (for when zoomed out) */
  const waypointDots = useMemo(
    () => ({
      minzoom: 0,
      maxzoom: 13.5,
      paint: {
        'circle-color': theme.palette.common.white,
        'circle-radius': ['interpolate', ['linear'], ['zoom'], 12, 3, 15.5, 6, 22, 6] as Expression,
        'circle-stroke-color': darken(theme.palette.secondary.main, 0.3),
        'circle-stroke-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          12,
          1,
          15.5,
          3,
          22,
          3,
        ] as Expression,
        // 'circle-opacity': Boolean(isWalk) ? 1 : 0,
      },
      layout: {
        visibility: isWalk ? ('visible' as 'visible') : ('none' as 'none'),
      },
    }),
    [theme, isWalk]
  );

  /** Data for source */
  const [{ walkWaypoints, additionalSites }] = useMapData();

  return (
    <>
      <Source id={ADDITIONAL_SITES_SOURCE} type="geojson" data={additionalSites}>
        <Layer id={ADDITIONAL_SITES} type="symbol" {...additionalSitesLayer} />
      </Source>
      <Source id={WAYPOINTS_SOURCE} type="geojson" data={walkWaypoints}>
        <Layer id={WAYPOINT_PINS} type="symbol" {...waypointPins} />
        <Layer id={WAYPOINT_DOTS} type="circle" beforeId={BEFORE_ID} {...waypointDots} />
      </Source>
    </>
  );
};
