import { useEffect, useState } from 'react';
import {
  FeatureCollection,
  LineString,
  Position,
  featureCollection,
  lineString,
} from '@turf/helpers';
import qs from 'query-string';

export interface useMapboxRouteArgs {
  waypoints?: Position[] | undefined | null;
  options: Record<string, string | number>;
}

/**
 * Hook that gets
 *
 */
export const useMapboxDirectionsAPI = ({
  waypoints,
  options,
}: useMapboxRouteArgs): FeatureCollection<LineString> | undefined => {
  /** Store result of AJAX request */
  const [data, set] = useState<FeatureCollection<LineString> | undefined>();

  /** Generate query string for API request */
  const queryString = qs.stringify({
    ...options,
    access_token: process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN,
  });

  /** Get a new route anytime the waypoints change. */
  useEffect(() => {
    /** Return early if there are no waypoints to prevent a pointless request */
    if (!waypoints) return;

    getMapboxDirections(waypoints, queryString).then(set);
  }, [waypoints, queryString]);

  return data;
};

export const getMapboxDirections = (waypoints: Position[], query: string) => {
  /** Generate URL to fetch */
  const url =
    // URL base
    `https://api.mapbox.com/directions/v5/mapbox/walking/` +
    // waypoints
    waypoints?.map(([longitude, latitude]) => `${longitude},${latitude}`).join(';') +
    '?' +
    // query string options with access token
    query;

  /** Fetch and process Mapbox Directions, set in state */
  return fetch(url)
    .then((res) => res.json())
    .then((data) => {
      if (data.code === 'Ok' && data.routes.length) {
        /** Log distance and summary between waypoints. */
        // data.routes[0].legs.forEach((leg: any) => {
        //   console.log(`
        //     distance: ${leg.distance}
        //     summary: ${leg.summary}
        //   `);
        // });

        /** Create valid feature collection */
        return featureCollection<LineString>([lineString(data.routes[0].geometry.coordinates)]);
      }
    });
};
