import { isMapboxReady } from './../common/mapboxReady';
import { useMapboxRef } from '@citydna/maps';
import { useEffect } from 'react';

import markerIcon from './sprites/com-marker-default.png';
import activeMarkerIcon from './sprites/com-marker-active.png';
import markerIconDark from './sprites/com-marker-default-dark.png';
import activeMarkerIconDark from './sprites/com-marker-active-dark.png';
import activeAdditionalSite from './sprites/additional-site-active.png';
import additionalSite from './sprites/additional-site.png';
import walkPin from './sprites/com-walk-pin.png';
import { Map } from 'mapbox-gl';
import { waitFor } from '../common/waitFor';

export const useMapboxIcons = () => {
  const [mapbox] = useMapboxRef();
  useEffect(() => {
    waitFor(
      () => isMapboxReady(mapbox),
      () => addImages(mapbox)
    );
  }, [mapbox]);
};

/** Each time  */
const addImages = (mapbox: Map) => {
  !mapbox.hasImage('com-walk-pin') &&
    mapbox.loadImage(walkPin, (err: Error, img: ImageData | HTMLImageElement) => {
      if (err) throw err;
      mapbox.addImage('com-walk-pin', img);
    });
  !mapbox.hasImage('default-com-marker') &&
    mapbox.loadImage(markerIcon, (err: Error, img: ImageData | HTMLImageElement) => {
      if (err) throw err;
      mapbox.addImage('default-com-marker', img);
    });
  !mapbox.hasImage('active-com-marker') &&
    mapbox.loadImage(activeMarkerIcon, (err: Error, img: ImageData | HTMLImageElement) => {
      if (err) throw err;
      mapbox.addImage('active-com-marker', img);
    });
  !mapbox.hasImage('default-com-marker-dark') &&
    mapbox.loadImage(markerIconDark, (err: Error, img: ImageData | HTMLImageElement) => {
      if (err) throw err;
      mapbox.addImage('default-com-marker-dark', img);
    });
  !mapbox.hasImage('active-com-marker-dark') &&
    mapbox.loadImage(activeMarkerIconDark, (err: Error, img: ImageData | HTMLImageElement) => {
      if (err) throw err;
      mapbox.addImage('active-com-marker-dark', img);
    });
  !mapbox.hasImage('active-additional-site') &&
    mapbox.loadImage(activeAdditionalSite, (err: Error, img: ImageData | HTMLImageElement) => {
      if (err) throw err;
      mapbox.addImage('active-additional-site', img);
    });
  !mapbox.hasImage('additional-site') &&
    mapbox.loadImage(additionalSite, (err: Error, img: ImageData | HTMLImageElement) => {
      if (err) throw err;
      mapbox.addImage('additional-site', img);
    });
};
