import React from 'react';
import gql from 'graphql-tag';
import {
  CardHeader,
  CardActions,
  Button,
  Card,
  CardMedia,
  makeStyles,
  Theme,
  CardContent,
  useMediaQuery,
} from '@material-ui/core';

import { MapCardFragment } from '../__generated__/graphql-types';
import { FCF } from './types';

import { RESPONSIVE_IMAGE_FRAGMENT } from './responsiveImageFragment';

import TimerRoundedIcon from '@material-ui/icons/TimerRounded';
import StraightenRoundedIcon from '@material-ui/icons/StraightenRounded';
import { MAX_SHEET_WIDTH } from '../walk/WalkSheet';
import { Picture } from './Picture';
import { Paragraph } from '@citydna/common';

export interface MapCardProps extends MapCardFragment {
  className?: string | undefined;
  hideButtons?: boolean | undefined;
}

/** Scoped material-ui/core stylesheet */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      borderBottom: 'none',
    },
    borderRadius: 0,
    width: '100%',
    borderLeft: 'none',
    borderRight: 'none',
    '& .MuiCardHeader-title': {
      // fontWeight: 'bold',
      fontSize: theme.typography.h2.fontSize || 20,
    },
    '& .MuiCardHeader-subheader': {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(0.5),
      '& .MuiSvgIcon-root': {
        width: 18,
        height: 18,
        position: 'relative',
        top: -2,
        marginRight: theme.spacing(0.5),
        fill: theme.palette.type === 'dark' ? theme.palette.grey[300] : theme.palette.grey[500],
        '&:nth-child(2)': {
          marginLeft: theme.spacing(1),
        },
      },
    },
  },
  media: {
    [theme.breakpoints.only('xs')]: {
      order: 2,
      '& img': {
        width: '100%',
        height: 'auto',
      },
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(2),
      float: 'left',
      '& img': {
        width: 100,
        height: 100,
      },
    },
  },
  header: {
    [theme.breakpoints.only('xs')]: {
      order: 1,
    },
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
      maxWidth: MAX_SHEET_WIDTH - 142,
      marginTop: theme.spacing(1),
    },
  },
  content: {
    [theme.breakpoints.only('xs')]: {
      order: 3,
    },
  },
  actions: {
    [theme.breakpoints.only('xs')]: {
      order: 4,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: -theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
  },
}));

/**
 * MapCard component. Displays simple WalkingMap information.
 * @param props walkingmap fields we want to display
 */
export const MapCard: FCF<MapCardProps> = ({
  name,
  description,
  distance: estimatedDistance,
  estimatedTime,
  previewImage,
  className = '',
  hideButtons = false,
}) => {
  // calculate metrics
  const hours = estimatedTime && parseFloat((estimatedTime / 60).toFixed(1));
  const distance = estimatedDistance && parseFloat((estimatedDistance / 1000).toFixed(1));

  // get styles from above
  const classes = useStyles();

  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  return (
    <Card className={`${classes.root} ${className}`}>
      {previewImage?.responsiveImage && (
        <CardMedia
          // @ts-ignore
          component={Picture}
          base64={previewImage?.responsiveImage?.base64}
          src={previewImage?.responsiveImage?.src}
          sources={[
            {
              srcSet: previewImage?.responsiveImage?.src + '&h=400&w=600',
              media: '(max-width: 600px)',
            },
            {
              srcSet: previewImage?.responsiveImage?.src + '&h=200&w=200',
              media: '(min-width: 600px)',
            },
          ]}
          className={classes.media}
        />
      )}

      <CardHeader
        className={classes.header}
        title={name}
        subheader={
          <>
            <TimerRoundedIcon />
            {estimatedTime && `${hours} hour${hours > 1 ? 's' : ''}`}
            <StraightenRoundedIcon />
            {estimatedDistance && `${distance}km`}
          </>
        }
      />
      {xs && description && (
        <CardContent className={classes.content}>
          <Paragraph>{description}</Paragraph>
        </CardContent>
      )}
      {!hideButtons && (
        <CardActions className={classes.actions}>
          <Button variant="text" component="span">
            Go to walk
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export const MAP_CARD_FRAGMENT = gql`
  fragment MapCard on WalkingMapRecord {
    id
    name
    description
    slug
    distance
    estimatedTime
    previewImage {
      responsiveImage(
        imgixParams: { fm: jpg, fit: crop }
        sizes: "(max-width: 600px) 600px, 200px"
      ) {
        ...responsiveImageFragment
      }
    }
  }
  ${RESPONSIVE_IMAGE_FRAGMENT}
`;

MapCard.fragments = {
  fields: MAP_CARD_FRAGMENT,
};
