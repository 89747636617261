import React, { Dispatch, useContext, useEffect, useReducer } from 'react';
import { FeatureCollection, featureCollection, Point, Feature, LineString } from '@turf/helpers';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GetWalkingMapQuery, GetWalkingMapQueryVariables } from '../__generated__/graphql-types';
import { WALKING_MAP_DATA } from '../walk';
import { useSnackbarError } from '@citydna/common';

type MapDataContextValues = [MapDataState, Dispatch<Partial<MapDataState>>];

interface MapDataState {
  walkPins: FeatureCollection<Point>;
  walkData: GetWalkingMapQuery['walkingMap'];
  walkPinPopup: Feature<Point> | undefined;
  walkRoute: FeatureCollection<LineString>;
  walkWaypoints: FeatureCollection<Point>;
  additionalSites: FeatureCollection<Point>;
}

const MapDataContext = React.createContext<MapDataContextValues | undefined>(undefined);

export const MapDataProvider: React.FC = (props) => {
  /** Store map data */
  const [state, dispatch] = useReducer<
    (state: MapDataState, action: Partial<MapDataState>) => MapDataState
  >((state, action) => ({ ...state, ...action }), {
    walkPins: featureCollection<Point>([]),
    walkData: undefined,
    walkPinPopup: undefined,
    walkRoute: featureCollection<LineString>([]),
    walkWaypoints: featureCollection<Point>([]),
    additionalSites: featureCollection<Point>([]),
  });

  /** Get walk data based on slug */
  const match = useRouteMatch<{ slug: string }>('/:slug/walk/:poiSlug?');
  const { data, error } = useQuery<GetWalkingMapQuery, GetWalkingMapQueryVariables>(
    WALKING_MAP_DATA,
    { variables: { slug: match?.params.slug }, skip: !match?.params.slug }
  );

  /** Add walkData to state when it becomes available */
  useEffect(() => {
    dispatch({ walkData: data?.walkingMap });
  }, [data]);

  /** Show GraphQL error if there is one. */
  useSnackbarError(error, 'There was a problem getting this walking map.');

  return <MapDataContext.Provider value={[state, dispatch]} {...props} />;
};

export const useMapData = () => {
  const ctx = useContext(MapDataContext);
  if (!ctx) throw Error();
  return ctx;
};
