import React, { FC, useEffect } from 'react';
import { Box, useMediaQuery, Theme } from '@material-ui/core';
import { useRouteMatch, useHistory } from 'react-router-dom';

import {
  WrappedInteractiveMap,
  useUpdateViewport,
  useMapboxRef,
  MELBOURNE_VIEWPORT,
  MELBOURNE_BOUNDS,
} from '@citydna/maps';
import { Bounds } from 'viewport-mercator-project';
import { PointerEvent } from 'react-map-gl';
import { GeolocationControl } from './GeolocationControl';
import { useMapboxIcons } from '../walk/useMapboxIcons';
import { useMapHeight } from './HeightContext';
import { WalkPinsLayer, WALK_PINS_SOURCE } from './WalkPins';
import { WalkRouteLayers } from './WalkRoute';
import { WalkWaypointsLayers, WAYPOINTS_SOURCE, ADDITIONAL_SITES_SOURCE } from './WalkWaypoints';
import { useMapData } from './MapDataProvider';
import { MapboxGeoJSONFeature } from 'mapbox-gl';

export const Map: FC = () => {
  /** Centre on Melbourne on mount */
  const { setViewportWithoutBounds, setGlobalBounds } = useUpdateViewport();
  useEffect(() => {
    setViewportWithoutBounds(MELBOURNE_VIEWPORT);
    setGlobalBounds(MELBOURNE_BOUNDS as Bounds);
  }, [setViewportWithoutBounds, setGlobalBounds]);

  /** Determine whether the user is on mob ile or not */
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  /** Determine whether the user is on home or not */
  const isHome = useRouteMatch({ path: '/', exact: true });

  /** Handle map pin click */
  const [{ walkData }, dispatch] = useMapData();
  const history = useHistory();
  const walkMatch = useRouteMatch<{ slug: string }>('/:slug/walk/');
  const handleClick = ({ features }: PointerEvent) => {
    /** Handle walk pin (from home) */
    if (isHome) {
      const walkPinFeature = features?.find(
        (f: MapboxGeoJSONFeature) => f.source === WALK_PINS_SOURCE
      );
      if (walkPinFeature) {
        dispatch({ walkPinPopup: undefined });
        history.push(`/${walkPinFeature.properties.slug}/walk/`);
      }
    }

    /** Handle click waypoint (mid-walk) */
    if (!isHome) {
      const waypointFeature = features?.find(
        (f: MapboxGeoJSONFeature) => f.source === WAYPOINTS_SOURCE
      );
      if (waypointFeature?.properties.index && walkData?.waypoints?.length) {
        const waypointData = walkData.waypoints[waypointFeature.properties.index - 1];
        history.push(
          `/${walkMatch?.params.slug}/walk/${waypointData?.pointOfInterest?.slug}` +
            (xs ? '/' : '/detail')
        );
      }
    }

    /** Handle click additionalSite (mid-walk) */
    if (!isHome) {
      const waypointFeature = features?.find(
        (f: MapboxGeoJSONFeature) => f.source === ADDITIONAL_SITES_SOURCE
      );
      if (waypointFeature?.properties.index && walkData?.otherPointsOfInterest?.length) {
        const waypointData =
          walkData.otherPointsOfInterest[waypointFeature.properties.additionalSitesIndex];
        history.push(
          `/${walkMatch?.params.slug}/walk/${waypointData?.slug}` + (xs ? '/' : '/detail')
        );
      }
    }
  };

  /** Handle popup interactiveity */
  const [{ walkPinPopup }] = useMapData();
  const [mapbox] = useMapboxRef();

  /** Handle map hover */
  const handleHover = ({ features }: PointerEvent) => {
    /** return early if the popup's origin is the popup itself for persistence */
    if (walkPinPopup?.properties?.popup) return;

    /** Otherwise, look for a feature and set/unset the popup state */
    const routeFeature = features?.find((f: MapboxGeoJSONFeature) => f.source === WALK_PINS_SOURCE);
    if (isHome && mapbox) {
      if (routeFeature) {
        dispatch({ walkPinPopup: routeFeature });
      } else {
        dispatch({ walkPinPopup: undefined });
      }
    }
  };

  /** Add icons to mapbox */
  useMapboxIcons();

  /** Get height of map (hack to fix flexbox height 100% bug...) */
  const [{ map: mapHeight }] = useMapHeight();

  /** Show the map if we're on the walk screen and mobile, or NOT mobile. */
  return (walkMatch && xs) || !xs ? (
    <Box
      flexGrow={1}
      height={mapHeight}
      position="relative"
      // @ts-ignore
      // ref={ref}
    >
      {/* <RecentreButton /> */}
      <WrappedInteractiveMap
        width="100%"
        height="100%"
        minZoom={12}
        maxZoom={18}
        attributionControl={false}
        onClick={handleClick}
        onHover={handleHover}
        reuseMap={false}
      >
        <GeolocationControl />
        <WalkPinsLayer />
        <WalkRouteLayers />
        <WalkWaypointsLayers />
      </WrappedInteractiveMap>
    </Box>
  ) : null;
};
