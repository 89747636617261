import gql from 'graphql-tag';

/**
 * This fragment is required by react-datocms (don't know why it's not in the package)
 * Be sure to include it before using it.
 *
 * @example
 * ```js
 * gql`
 *    allPosts {
 *      image: {
 *        responsiveImage: { ...responsiveImageFragment }
 *      }
 *    }
 *    ${RESPONSIVE_IMAGE_FRAGMENT}
 * `
 * ```
 */
export const RESPONSIVE_IMAGE_FRAGMENT = gql`
  fragment responsiveImageFragment on ResponsiveImage {
    srcSet
    # webpSrcSet
    sizes
    src
    width
    height
    aspectRatio
    alt
    title
    # bgColor
    base64
  }
`;
