import React, { forwardRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useSnackbarError, Subtitle, HtmlToMui, Skrim } from '@citydna/common';
import Slider from 'react-slick';
import { Box, IconButton, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { RESPONSIVE_IMAGE_FRAGMENT } from '../common/responsiveImageFragment';
import { Skeleton } from '@material-ui/lab';
import { Image } from 'react-datocms';
import { GetPoiQuery, GetPoiQueryVariables } from '../__generated__/graphql-types';
import gql from 'graphql-tag';
import { MAX_SHEET_WIDTH } from './WalkSheet';

/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: MAX_SHEET_WIDTH,
    },
    borderRight: '1px solid',
    borderRightColor:
      theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
  },
  iconButton: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 9,
  },
  icon: {
    color: theme.palette.common.white,
  },
  skrim: {
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: 100,
    transform: 'rotate(180deg)',
    zIndex: 8,
    opacity: 0.5,
  },
  slider: {
    '& .slick-dots': {
      display: 'flex !important',
      justifyContent: 'center',
      width: '100%',
      position: 'absolute',
      bottom: theme.spacing(2),
      margin: 0,
      padding: 0,
      '& li': {
        listStyle: 'none',
      },
      '& button': {
        backgroundColor: theme.palette.common.white,
        border: 'none',
        margin: theme.spacing(0.5),
        width: 8,
        height: 8,
        transition: theme.transitions.create('width'),
        overflow: 'hidden',
        textIndent: 24,
        borderRadius: 4,
        padding: 0,
      },
      '& li.slick-active button': {
        width: 16,
      },
    },
  },
}));

export const PoiDetail = forwardRef((_props, ref) => {
  const { slug, poiSlug } = useParams();
  const { data, loading, error } = useQuery<GetPoiQuery, GetPoiQueryVariables>(GET_POI_QUERY, {
    variables: { slug: poiSlug },
  });
  useSnackbarError(error, 'There was an error getting the point of interest');

  const classes = useStyles();

  const poi = data?.pointOfInterest;

  /** Return to walk */
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const history = useHistory();
  const backToWalk = () => {
    xs ? history.push(`/${slug}/walk/${poiSlug}`) : history.push(`/${slug}/walk/preview`);
  };

  return (
    <Box
      // @ts-ignore
      ref={ref}
      position="absolute"
      zIndex={1301}
      left={0}
      right={0}
      bottom={0}
      top={0}
      bgcolor="common.white"
      className={classes.root}
    >
      <IconButton className={classes.iconButton} onClick={backToWalk}>
        <CloseIcon className={classes.icon} />
      </IconButton>
      <Slider arrows={false} dots={true} className={classes.slider}>
        {loading && <Skeleton width="100%" height={200} />}
        {!loading &&
          poi?.photos.length &&
          poi?.photos.map((photo) =>
            photo.responsiveImage ? <Image data={photo.responsiveImage} key={photo.id} /> : null
          )}
      </Slider>
      <Skrim className={classes.skrim} />
      <Box p={2}>
        <Subtitle primary={poi?.name} loading={loading} />
        <HtmlToMui html={poi?.description || undefined} />
      </Box>
    </Box>
  );
});

export const GET_POI_QUERY = gql`
  query GetPoi($slug: String) {
    pointOfInterest(filter: { slug: { eq: $slug } }) {
      __typename
      id
      slug
      name
      description
      externalWebsite
      photos {
        __typename
        id
        responsiveImage(imgixParams: { fm: jpg, h: 200, w: 600, ar: "16:9", fit: crop }) {
          ...responsiveImageFragment
        }
      }
    }
  }
  ${RESPONSIVE_IMAGE_FRAGMENT}
`;
