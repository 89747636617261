import React from 'react';
import {
  Box,
  Fab,
  makeStyles,
  // Typography,
  Theme as ThemeType,
  IconButton,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
} from '@material-ui/core';
import DirectionsIcon from '@material-ui/icons/DirectionsRounded';
import { MapCard } from '../common/MapCard';
import gql from 'graphql-tag';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import {
  WalkingMapDetailQuery,
  WalkingMapDetailQueryVariables,
} from '../__generated__/graphql-types';
import { useSnackbarError, Title, Paragraph, Skrim } from '@citydna/common';

import { Image } from 'react-datocms';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import TimerRoundedIcon from '@material-ui/icons/TimerRounded';
import StraightenRoundedIcon from '@material-ui/icons/StraightenRounded';
import { MAX_SHEET_WIDTH } from '../walk/WalkSheet';

/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme: ThemeType) => ({
  backButton: {
    position: (hasImage) => (hasImage ? 'absolute' : 'static'),
    top: theme.spacing(2),
    left: theme.spacing(2),
    color: (hasImage) => (hasImage ? theme.palette.grey[50] : 'inherit'),
    zIndex: 9,
  },
  fab: {
    marginBottom: theme.spacing(2),
  },
  media: {
    height: theme.spacing(30),
  },
  list: {
    '& .MuiListItem-root': {
      paddingLeft: 0,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 24,
      marginRight: theme.spacing(1),
    },
    '& .MuiListItemText-root': {
      position: 'relative',
      top: 2,
      color: theme.palette.grey[800],
      fontSize: 14,
    },
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
  skrim: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    transform: 'rotate(180deg)',
    opcity: 0.5,
    height: 150,
  },
  wrapper: {
    borderRight: 'none',
  },
}));

export const Detail: React.FC = () => {
  /** Get additional data from the API */
  const { slug } = useParams();
  const { data, error } = useQuery<WalkingMapDetailQuery, WalkingMapDetailQueryVariables>(
    WALKING_MAP_DETAIL,
    { variables: { slug }, returnPartialData: true }
  );

  /** Handle API Error */
  useSnackbarError(error, 'There was an error loading the walk.');

  /** Handle start walk */
  const history = useHistory();
  const startWalk = (slug: string | undefined | null) =>
    startWalk && history.push(`/${slug}/walk/preview`);

  /** Scoped styles */
  const classes = useStyles(Boolean(data?.walkingMap?.previewImage));

  /** Determine if the user is on mobile */
  const xs = useMediaQuery((theme: ThemeType) => theme.breakpoints.only('xs'));

  if (data?.walkingMap) {
    const {
      estimatedTime,
      distance: estimatedDistance,
      name,
      description,
      previewImage,
      slug,
    } = data.walkingMap;

    /** Calculate times & distances */
    const hours = estimatedTime && parseFloat((estimatedTime / 60).toFixed(1));
    const distance = estimatedDistance && parseFloat((estimatedDistance / 1000).toFixed(1));

    return (
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={MAX_SHEET_WIDTH}
        borderRight={{ xs: 'none', sm: '1px solid' }}
        className={classes.wrapper}
        position="relative"
      >
        {previewImage?.responsiveImage && (
          <>
            <CardMedia component={Image} data={previewImage.responsiveImage} />
            <Skrim className={classes.skrim} />
          </>
        )}
        <Box mt={1} p={2}>
          <IconButton
            edge="start"
            onClick={() => history.push(`/`)}
            aria-label="Back to all walks"
            className={classes.backButton}
          >
            <ArrowBackRoundedIcon />
          </IconButton>
          <Title primary={name} />
          <List dense className={classes.list}>
            {estimatedTime && (
              <ListItem>
                <ListItemIcon>
                  <TimerRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${hours} hour${hours > 1 ? 's' : ''}`}
                  primaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
            )}
            {estimatedDistance && (
              <ListItem>
                <ListItemIcon>
                  <StraightenRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${distance}km`}
                  primaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
            )}
          </List>
          <Paragraph>{description}</Paragraph>
        </Box>

        {xs && (
          <Box
            position="fixed"
            bottom={0}
            width={1}
            display="flex"
            justifyContent="center"
            zIndex={99}
          >
            <Fab variant="extended" className={classes.fab} onClick={() => startWalk(slug)}>
              <DirectionsIcon />
              Preview walk
            </Fab>
          </Box>
        )}
      </Box>
    );
  }

  return null;
};

export const WALKING_MAP_DETAIL = gql`
  query WalkingMapDetail($slug: String) {
    walkingMap(filter: { slug: { eq: $slug } }) {
      ...MapCard
      description
      slug
    }
  }
  ${MapCard.fragments?.fields}
`;
