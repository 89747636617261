import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

export const DATO_GRAPHQL_URL = 'https://graphql.datocms.com/';

export interface createDatoCMSClientArgs {
  token: string | undefined;
  apiUrl?: string | undefined;
}

export const createClient = ({ token, apiUrl = DATO_GRAPHQL_URL }: createDatoCMSClientArgs) => {
  const httpLink = createHttpLink({
    uri: apiUrl,
  });

  const authLink = setContext((_, { headers }: any) => {
    return {
      headers: Object.assign(headers || {}, {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return client;
};
