import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { createClient, createDatoCMSClientArgs } from './createClient';

/** DatoCMSProvider props are the same as createClient. */
export interface DatoCMSProviderProps extends createDatoCMSClientArgs {}

export const DatoCMSProvider: React.FC<DatoCMSProviderProps> = ({ token, apiUrl, children }) => {
  const client = createClient({ token, apiUrl });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
