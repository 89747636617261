import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbarError } from '@citydna/common';
import { ListWalkingMapsQuery } from '../__generated__/graphql-types';
import { MapCard } from '../common/MapCard';
import { ButtonBase, makeStyles, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[100],
  },
  button: {
    width: '100%',
    textAlign: 'left',
  },
}));

export const WalkingMapsList: React.FC = () => {
  // Get data from DatoCMS
  const { data, error } = useQuery<ListWalkingMapsQuery>(LIST_WALKING_MAPS);

  // handle error
  useSnackbarError(error, 'There was an error loading the walks! Please refresh.');

  // handle tap
  const history = useHistory();
  const showDetail = (slug: string | undefined | null) =>
    slug && history.push(`/${slug}/walk/preview`);

  // css classes
  const classes = useStyles();

  return (
    <div className={classes.background}>
      {data?.allWalkingMaps.map((map) => (
        <ButtonBase key={map.id} onClick={() => showDetail(map.slug)} className={classes.button}>
          <MapCard {...map} />
        </ButtonBase>
      ))}
    </div>
  );
};

export const LIST_WALKING_MAPS = gql`
  query ListWalkingMaps {
    allWalkingMaps {
      slug
      ...MapCard
    }
  }
  ${MapCard.fragments?.fields}
`;
