import React, { Dispatch, createContext, useReducer, useContext } from 'react';
import { useWindowHeight } from '@react-hook/window-size';
import { useMediaQuery, Theme } from '@material-ui/core';

/** Shape of height context state */
type HeightState = {
  header: number;
  sheet: number;
  map: number;
};

/** React context to hold heights */
export const MapHeightContext = createContext<
  [HeightState, Dispatch<Partial<HeightState>>] | undefined
>(undefined);

/** Provider of height context */
export const MapHeightProvider: React.FC = (props) => {
  /** Keep track of header, map and sheet heights */
  const [state, dispatch] = useReducer(
    (state: Partial<HeightState>, action: Partial<HeightState>) => ({ ...state, ...action }),
    { sheet: 0, header: 0, map: 0 }
  );

  const windowHeight = useWindowHeight();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const heights: HeightState = Object.entries(state).reduce(
    (acc, [key, value]) => {
      if (key === 'map')
        return {
          ...acc,
          [key]: isMobile
            ? windowHeight - ((state.header || 0) + (state.sheet || 0))
            : windowHeight - (state.header || 0),
        };
      return { ...acc, [key]: value };
    },
    { sheet: 0, header: 0, map: 0 }
  );

  return <MapHeightContext.Provider value={[heights, dispatch]} {...props} />;
};

export const useMapHeight = () => {
  const context = useContext(MapHeightContext);
  if (!context) throw Error('Not inside <HeightProvider />.');
  return context;
};
