import React, {
  useRef,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  MutableRefObject,
} from 'react';
import { useMapData } from '../common/MapDataProvider';
import { useRouteMatch } from 'react-router-dom';

interface WalkControllerProviderValues {
  slideIndex: number;
  setSlideIndex: Dispatch<SetStateAction<number>>;
  sheetIndex: number;
  setSheetIndex: Dispatch<SetStateAction<number>>;
  bottomSheetDraggingRef: MutableRefObject<boolean>;
  activeIndex: number | undefined;
}

const WalkControllerContext = React.createContext<WalkControllerProviderValues | undefined>(
  undefined
);

/**
 * Shares slide and sheet state across the walk screen.
 * access it via the hook below - useWalkScreenController.
 */
export const WalkControllerProvider: React.FC = ({ children }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [sheetIndex, setSheetIndex] = useState(0);
  const bottomSheetDraggingRef = useRef<boolean>(false);

  const [{ walkData }] = useMapData();

  /** Get active index */
  const match = useRouteMatch<{ slug: string }>('/:slug/walk/:poiSlug?');

  // @ts-ignore
  const waypoints = walkData?.waypoints?.map(({ pointOfInterest }) => pointOfInterest) || [];
  const additionalSites = walkData?.otherPointsOfInterest || [];
  const allPoints = [...waypoints, ...additionalSites];

  const activeIndex = allPoints.findIndex(
    // @ts-ignore
    (waypoint) => waypoint.slug === match?.params.poiSlug
  );

  return (
    <WalkControllerContext.Provider
      value={{
        slideIndex,
        setSlideIndex,
        sheetIndex,
        setSheetIndex,
        bottomSheetDraggingRef,
        activeIndex,
      }}
    >
      {children}
    </WalkControllerContext.Provider>
  );
};

export const useWalkScreenController = () => {
  const context = useContext(WalkControllerContext);
  if (!context) throw Error('Not inside <WalkControllerProvider />.');
  return context;
};
