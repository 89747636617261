import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { Title, Paragraph, useSnackbarError } from '@citydna/common';
import { useMapHeight } from '../common/HeightContext';
import { useWindowHeight } from '@react-hook/window-size';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useMapData } from '../common/MapDataProvider';
import { point, featureCollection, Point } from '@turf/helpers';
import { useUpdateViewport, useMapboxRef } from '@citydna/maps';
import { isMapboxReady } from '../common/mapboxReady';
import { RESPONSIVE_IMAGE_FRAGMENT } from '../common/responsiveImageFragment';
import { waitFor } from '../common/waitFor';
import { MAX_SHEET_WIDTH } from '../walk/WalkSheet';
import { AllWalkRoutesQuery } from '../__generated__/graphql-types';
// import { Helmet } from 'react-helmet';

/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRightColor:
      theme.palette.type === 'dark' ? theme.palette.grey[500] : theme.palette.grey[200],
    [theme.breakpoints.up('sm')]: {
      /** Weird - wouldn't take it in Box height */
      height: (height) => height,
    },
  },
}));

export const Home: React.FC = ({ children }) => {
  const { data, error } = useQuery<AllWalkRoutesQuery>(ALL_WALK_ROUTES);
  useSnackbarError(error, 'Error getting the pins');
  const { fitViewportToFeature } = useUpdateViewport();
  const [, dispatch] = useMapData();
  const [mapbox] = useMapboxRef();
  React.useEffect(() => {
    if (data) {
      const walkPins = featureCollection<Point>(
        data.allWalkingMaps.flatMap((walk) => {
          const { waypoints, ...properties } = walk;
          if (!waypoints) return [];

          return [
            point(
              [
                waypoints[0]?.pointOfInterest?.location?.longitude,
                waypoints[0]?.pointOfInterest?.location?.latitude,
              ],
              properties
            ),
          ];
        })
      );

      dispatch({ walkPins });

      waitFor(
        () => isMapboxReady(mapbox),
        () => {
          fitViewportToFeature(walkPins, {
            padding: {
              top: 48,
              left: 48,
              bottom: 48,
              right: 144,
            },
          });
        }
      );
    }
  }, [data, dispatch, fitViewportToFeature, mapbox]);

  const [{ header }] = useMapHeight();
  const windowHeight = useWindowHeight();
  const classes = useStyles(windowHeight - header);
  return (
    <>
      <Box
        maxWidth={{ sm: MAX_SHEET_WIDTH }}
        borderRight={{ xs: 'none', sm: '1px solid' }}
        overflow={{ xs: 'auto', sm: 'hidden scroll' }}
        className={classes.root}
      >
        <Box p={2} pt={4.5} pb={{ sm: 4 }}>
          <Title primary="Melbourne Walks" />
          <Paragraph>
            Our self-guided walks will help you discover the city on foot, whether you have a whole
            week to immerse yourself or only a few hours to explore.
          </Paragraph>
        </Box>
        {children}
      </Box>
    </>
  );
};

export const ALL_WALK_ROUTES = gql`
  query AllWalkRoutes {
    allWalkingMaps {
      id
      slug
      name
      distance
      estimatedTime
      previewImage {
        responsiveImage(imgixParams: { fm: jpg, fit: crop, ar: "16:9", w: 480 }) {
          ...responsiveImageFragment
        }
      }
      waypoints {
        __typename
        pointOfInterest {
          __typename
          location {
            __typename
            latitude
            longitude
          }
        }
      }
    }
    site: _site {
      favicon: faviconMetaTags {
        attributes
        content
        tag
      }
    }
  }
  ${RESPONSIVE_IMAGE_FRAGMENT}
`;
