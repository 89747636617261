import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import gql from 'graphql-tag';
import { useHistory, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { FCF } from '../common/types';
import { RESPONSIVE_IMAGE_FRAGMENT } from '../common/responsiveImageFragment';
import { GetWalkingMapQuery, PointOfInterestRecord } from '../__generated__/graphql-types';
import { useWalkScreenController } from './WalkControllerProvider';
import { MapCard } from '../common/MapCard';
import { useMapData } from '../common/MapDataProvider';
import { WaypointItem } from './WaypointItem';

/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme: Theme) => ({
  slick: {
    '& .slick-list': {
      marginTop: theme.spacing(2),
    },
    '& .slick-slide': {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },

  mapCard: {
    marginTop: -theme.spacing(2),
    borderTop: 'none',
    '& .MuiCardHeader-root': {
      borderTop: 'none',
    },
  },
}));

export const WalkCarousel: FCF<Partial<GetWalkingMapQuery['walkingMap']>> = ({ name }) => {
  const [{ walkData }] = useMapData();
  const { activeIndex } = useWalkScreenController();

  /** find the active index based on the slug */
  const { slug } = useParams();

  /** Store ref to slider (used state instead of ref so we can react to it being set) */
  const [sliderRef, setSliderRef] = useState<Slider | null>();

  /** Update the slider when then slug changes */
  useEffect(() => {
    if (sliderRef && activeIndex !== undefined) {
      sliderRef.slickGoTo(activeIndex);
    }
  }, [activeIndex, sliderRef]);

  /** Update the slug when the carousel changes */
  const handleSlideChange = (index: number) => {
    if (!walkData?.waypoints) return;
    const waypoint = walkData?.waypoints[index];
    if (waypoint) {
      history.push(`/${slug}/walk/${waypoint.pointOfInterest?.slug}`);
    }
  };

  /** Go to detail screen */
  const history = useHistory();
  const handleMoreInfoClick = (waypoint: Partial<PointOfInterestRecord>) => {
    history.replace(`/${slug}/walk/${waypoint.slug}/detail`);
  };

  /** Tap slick item */
  const handleClick = (waypoint: Partial<PointOfInterestRecord>, i: number) =>
    i === activeIndex
      ? handleMoreInfoClick(waypoint)
      : history.push(`/${slug}/walk/${waypoint.slug}`);

  const classes = useStyles();

  return (
    <>
      <MapCard
        id={walkData?.id}
        name={walkData?.name}
        distance={walkData?.distance}
        estimatedTime={walkData?.estimatedTime}
        className={classes.mapCard}
        hideButtons
      />
      <Box pl={2}>{!walkData?.waypoints?.length && <Skeleton width={300} height={200} />}</Box>
      {walkData?.waypoints?.length && (
        <Slider
          arrows={false}
          infinite={false}
          slidesToShow={1}
          ref={(ref) => setSliderRef(ref)}
          afterChange={handleSlideChange}
          className={classes.slick}
          centerMode
          adaptiveHeight
        >
          {walkData?.waypoints?.map((waypoint, i) => (
            <WaypointItem
              // @ts-ignore
              key={waypoint.pointOfInterest.id}
              // @ts-ignore
              waypoint={waypoint}
              index={i}
              onClick={() => waypoint?.pointOfInterest && handleClick(waypoint?.pointOfInterest, i)}
            />
          ))}
          {walkData.otherPointsOfInterest.map((pointOfInterest, i) => (
            <WaypointItem
              // @ts-ignore
              key={pointOfInterest.id}
              waypoint={{ pointOfInterest, directionalHelper: 'Additional stop' } as any}
              index={(walkData.waypoints?.length || 0) + i}
              onClick={() =>
                pointOfInterest &&
                handleClick(pointOfInterest, (walkData.waypoints?.length || 0) + i)
              }
            />
          ))}
        </Slider>
      )}
    </>
  );
};

export const WALK_CAROUSEL_FRAGMENT = gql`
  fragment WalkCarousel on WalkingMapRecord {
    name
    id
    waypoints {
      directionalHelper
      distanceToNextWaypoint
      pointOfInterest {
        id
        name
        slug
        photos {
          responsiveImage(imgixParams: { fm: jpg, fit: crop }) {
            ...responsiveImageFragment
          }
        }
      }
    }
    otherPointsOfInterest {
      id
      name
      slug
      photos {
        responsiveImage(imgixParams: { fm: jpg, fit: crop }) {
          ...responsiveImageFragment
        }
      }
    }
  }
  ${RESPONSIVE_IMAGE_FRAGMENT}
`;

WalkCarousel.fragments = {
  fields: WALK_CAROUSEL_FRAGMENT,
};
