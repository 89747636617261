import React, { useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useMapData } from './MapDataProvider';
import { useTheme } from '@material-ui/core';
import { Expression } from 'mapbox-gl';
import { useRouteMatch } from 'react-router-dom';
import { WalkPopup } from '../home/WalkPopup';

export const WALK_PINS_SOURCE = 'walking-maps-pins-source';
export const WALK_PINS_LAYER = 'walking-maps-pins-layer';

export const WalkPinsLayer: React.FC = () => {
  /** Theme for global usage */
  const theme = useTheme();

  /** Walk pins */
  const isHome = useRouteMatch({ path: '/', exact: true });
  const [{ walkPins }] = useMapData();
  const walkPinLayer = useMemo(
    () => ({
      paint: {
        'text-color': theme.palette.secondary.main,
        'text-halo-color': '#fff',
        'text-halo-width': 2,
        'text-opacity': isHome ? 1 : 0,
        'icon-opacity': isHome ? 1 : 0,
      },
      layout: {
        'icon-image': 'com-walk-pin',
        'icon-size': 0.33,
        'icon-anchor': 'bottom' as 'bottom',
        'text-anchor': 'left' as 'left',
        'text-offset': [1.2, -1.25],
        'text-field': ['get', 'name'] as Expression,
        'text-size': 14,
        'text-justify': 'left' as 'left',
        'text-font': ['Gotham Book'],
      },
    }),
    [isHome, theme.palette.secondary.main]
  );

  return (
    <>
      <WalkPopup />
      <Source id={WALK_PINS_SOURCE} type="geojson" data={walkPins}>
        <Layer id={WALK_PINS_LAYER} type="symbol" {...walkPinLayer} />
      </Source>
    </>
  );
};
