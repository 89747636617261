import React from 'react';
import { Subtitle, Button } from '@citydna/common';
import { Box, Theme, makeStyles } from '@material-ui/core';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalkRounded';
import { FCF } from '../common/types';
import gql from 'graphql-tag';
import { GetWalkingMapQuery } from '../__generated__/graphql-types';
import { useHistory, useParams } from 'react-router-dom';
import { MAX_SHEET_WIDTH } from './WalkSheet';

const useStyles = makeStyles((theme: Theme) => ({
  paperButton: {
    minWidth: '48%',
  },
  subtitle: {
    '& h2': {
      marginBottom: theme.spacing(0.5),
    },
    '& h4': {
      color: theme.palette.grey[400],
    },
  },
}));

export const WalkPreview: FCF<Partial<GetWalkingMapQuery['walkingMap']>> = ({
  name,
  distance: estimatedDistance,
  estimatedTime,
  waypoints,
}) => {
  /** calculate metrics */
  const hours = estimatedTime && parseFloat((estimatedTime / 60).toFixed(1));
  const distance = estimatedDistance && parseFloat((estimatedDistance / 1000).toFixed(1));
  const firstStop = waypoints?.length && waypoints[0]?.pointOfInterest?.name;
  const lastStop = waypoints?.length && waypoints[waypoints?.length - 1]?.pointOfInterest?.name;

  /** For routing */
  const { slug } = useParams();
  const history = useHistory();

  /** Get generated CSS classes (see makeStyles before component def) */
  const classes = useStyles();

  return (
    <Box pl={2} pr={2} maxWidth={{ xs: '100%', sm: MAX_SHEET_WIDTH }}>
      <Subtitle
        primary=""
        secondary={`${hours} hours (${distance}km)`}
        className={classes.subtitle}
        loading={!hours}
        mb={0.5}
      />
      <Subtitle
        loading={!name}
        primary={name}
        secondary={`${waypoints?.length} stops, starting at ${firstStop} and finishing at ${lastStop}`}
        className={classes.subtitle}
        mb={2}
      />
      <Box display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          className={classes.paperButton}
          onClick={() => history.push(`/${slug}`)}
        >
          Go back
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<DirectionsWalkIcon />}
          className={classes.paperButton}
          onClick={() =>
            history.push(`/${slug}/walk/${waypoints && waypoints[0]?.pointOfInterest?.slug}`)
          }
        >
          Start walk
        </Button>
      </Box>
    </Box>
  );
};

const WALK_PREVIEW_FRAGMENT = gql`
  fragment WalkPreview on WalkingMapRecord {
    name
    distance
    estimatedTime
    waypoints {
      pointOfInterest {
        __typename
        name
        slug
      }
    }
  }
`;

WalkPreview.fragments = {
  fields: WALK_PREVIEW_FRAGMENT,
};
