/**
 * Pings a predicate function every 50ms before calling a callback
 *
 * @param predicate if we've waited long enough
 * @param action callback to call when predicate is true
 * @param delay time to ping for predicate
 */
export function waitFor(
  predicate: () => boolean,
  action: () => void,
  delay: number = 50,
  maxRetries = 50
) {
  /** track retries */
  let retries = 0;

  /** Scope to retries */
  function handleWaitFor() {
    if (predicate()) {
      action();
    } else if (retries <= maxRetries) {
      retries++;
      setTimeout(() => handleWaitFor(), delay);
    }
  }

  /** run first call */
  handleWaitFor();
}
