import gql from 'graphql-tag';
import React, { FC, useEffect, useMemo } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import { useMapboxRef, useUpdateViewport } from '@citydna/maps';
import { Fab, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { featureCollection, point, Point } from '@turf/helpers';
import { PoiDetail } from './PoiDetail';
import { useMapboxDirectionsAPI } from './useMapboxDirectionsAPI';
import { WalkCarousel } from './WalkCarousel';
import { WalkPreview } from './WalkPreview';
import { WalkSheet } from './WalkSheet';
import { isMapboxReady } from '../common/mapboxReady';
import { DesktopWalk } from './DesktopWalk';
import { useMapData } from '../common/MapDataProvider';
import { waitFor } from '../common/waitFor';

/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(6),
    border: 'none',
    borderTop:
      '1px solid ' + theme.palette.type === 'dark'
        ? theme.palette.grey[500]
        : theme.palette.grey[200],
  },
  exitFab: {
    position: 'absolute',
    zIndex: 1050,
    left: theme.spacing(2),
    top: theme.spacing(2),
  },
}));

export const Walk: FC = React.memo(() => {
  /** Get data from API */
  const [{ walkData }] = useMapData();

  /** Memoised waypoints array */
  const waypoints = useMemo(
    () =>
      // @ts-ignore
      walkData?.waypoints?.map(({ pointOfInterest }) => [
        pointOfInterest?.location?.longitude,
        pointOfInterest?.location?.latitude,
      ]),
    [walkData]
  );

  /** Get LineString feature collection from Mapbox Directions API */
  const walkRoute = useMapboxDirectionsAPI({
    waypoints,
    options: {
      geometries: 'geojson',
      alley_bias: 1,
      walkway_bias: 1,
    },
  });

  /** Update the Route LineString source data when it becomes available */
  const [, dispatch] = useMapData();
  const [mapbox] = useMapboxRef();
  useEffect(() => {
    /** Return early if we don't have everything we need */
    if (!mapbox || mapbox === undefined || !walkRoute) return;

    /** Set data */
    dispatch({ walkRoute: walkRoute });
  }, [mapbox, walkRoute, dispatch]);

  /** Update the waypoints source data when it becomes available */
  useEffect(() => {
    /** Return early if we don't have everything we need */
    if (!mapbox || !waypoints || !walkData) return;
    const walkWaypoints = featureCollection(
      waypoints.map(([longitude, latitude], i) => {
        return point([longitude, latitude], {
          index: i + 1,
          // @ts-ignore
          slug: walkData?.waypoints[i].pointOfInterest.slug,
        });
      })
    );
    dispatch({ walkWaypoints });
  }, [mapbox, waypoints, dispatch, walkData]);

  /** Add additional sites */
  useEffect(() => {
    if (!walkData || !mapbox) return;

    const additionalSites = featureCollection<Point>(
      walkData.otherPointsOfInterest.map(({ location, slug }, i) =>
        point([location?.longitude, location?.latitude], {
          index: (walkData.waypoints?.length || 0) + i + 1,
          additionalSitesIndex: i,
          slug,
        })
      )
    );

    dispatch({ additionalSites });
  }, [mapbox, walkData, dispatch]);

  /** Fit map to entire route on preview  */
  const location = useLocation();
  const { fitViewportToFeature } = useUpdateViewport();
  useEffect(() => {
    if (!mapbox || !walkRoute || !location.pathname.includes('walk/preview')) return;

    /** Wait for map to load, then set viewport to route if in preview */
    waitFor(
      () => isMapboxReady(mapbox),
      () => fitViewportToFeature(walkRoute, { padding: 48 })
    );
  }, [location.pathname, fitViewportToFeature, walkRoute, mapbox]);

  /** Move the map to centre on the current pin */
  // const { activeIndex } = useWalkScreenController();
  const { setViewport } = useUpdateViewport();

  const { poiSlug } = useParams();
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  useEffect(() => {
    // if (activeIndex === undefined) return;
    // @ts-ignore
    const waypoints = walkData?.waypoints?.map(({ pointOfInterest }) => pointOfInterest) || [];
    const additionalSites = walkData?.otherPointsOfInterest || [];
    const allPoints = [...waypoints, ...additionalSites];
    const current = allPoints.find((p) => p.slug === poiSlug);

    if (current) {
      setViewport({
        longitude: current.location.longitude,
        latitude: current.location.latitude,
        zoom: xs ? 15 : 17,
        transitionDuration: 1500,
        transitionEasing: (t: number) =>
          t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
      });
    }
  }, [poiSlug, setViewport, walkData, xs]);

  /** get history object for routing */
  const history = useHistory();

  /** Get generated CSS classes */
  const classes = useStyles();

  /** show detail? */
  const showDetail = useRouteMatch('/:slug/walk/:poiSlug/detail');

  return (
    <>
      {/* <Helmet></Helmet> */}
      {showDetail && <PoiDetail />}
      {xs && (
        <Fab
          aria-label="Back to walk list"
          className={classes.exitFab}
          onClick={() => history.push(`/`)}
        >
          <CloseIcon />
        </Fab>
      )}
      <WalkSheet>
        <Switch>
          <Route path="/:slug/walk/preview">
            {xs ? <WalkPreview {...(walkData || {})} /> : <DesktopWalk />}
          </Route>
          <Route path="/:slug/walk/:poiSlug/:detail?">
            {xs ? <WalkCarousel /> : <DesktopWalk />}
          </Route>
          <Redirect to="/:slug/walk/preview" />
        </Switch>
      </WalkSheet>
    </>
  );
});

/**
 * Coalesced query, actually used in WalkControllerProvider
 */
export const WALKING_MAP_DATA = gql`
  query GetWalkingMap($slug: String) {
    walkingMap(filter: { slug: { eq: $slug } }) {
      __typename
      id
      slug
      routeGeojsonLinestring {
        __typename
        url
      }
      waypoints {
        id
        pointOfInterest {
          id
          __typename
          location {
            __typename
            latitude
            longitude
          }
        }
      }
      otherPointsOfInterest {
        id
        slug
        __typename
        location {
          __typename
          latitude
          longitude
        }
      }
      ...WalkPreview
      ...WalkCarousel
    }
  }
  ${WalkPreview.fragments?.fields}
  ${WalkCarousel.fragments?.fields}
`;
