import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';

type ResponsiveImageBreakpoint = {
  media: string;
  srcSet: string;
};

interface ResponsiveImageProps {
  base64?: string | null | undefined;
  className?: string | undefined;
  sources: ResponsiveImageBreakpoint[];
  src: string;
  title?: string | undefined;
}

/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles<Theme, { loaded: boolean }>((theme) => ({
  root: {
    position: 'relative',
  },
  image: {
    position: 'relative',
    zIndex: 2,
    opacity: ({ loaded }) => (loaded ? 1 : 0),
    transition: theme.transitions.create('opacity'),
  },
  blurImage: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
  },
}));

/**
 * An image that
 */
export const Picture: React.FC<ResponsiveImageProps> = ({
  base64,
  className,
  sources,
  src,
  title,
  ...props
}) => {
  const [loaded, setLoaded] = useState(false);
  const classes = useStyles({ loaded });

  return (
    <div className={`${className} ${classes.root}`}>
      <picture onLoad={() => setLoaded(true)} {...props}>
        {sources.map((source, i) => (
          <source key={source.srcSet + i} srcSet={source.srcSet} media={source.media} />
        ))}
        <img src={src} alt={title} className={classes.image} />
      </picture>
      {base64 && (
        <img
          alt={`Blurry version of ${title || 'image'}`}
          className={classes.blurImage}
          src={base64}
        />
      )}
    </div>
  );
};
