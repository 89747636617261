import React, { useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useMapData } from './MapDataProvider';
import { darken, useTheme } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { Expression } from 'mapbox-gl';

export const ROUTE_SOURCE = 'walking-map-route-source';

export const BEFORE_ID = 'waterway-label';

export const ROUTE_LAYER = 'walking-map-route-layer';
export const ROUTE_LAYER_CAP = 'walking-map-route-cap';

export const WalkRouteLayers: React.FC = () => {
  const theme = useTheme();
  const isWalk = useRouteMatch('/:slug/walk');

  /** Darkened primary colour route cap */
  const routeLayerCap = useMemo(
    () => ({
      paint: {
        'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 13, 3, 15.5, 9] as Expression,
        'line-color': darken(theme.palette.secondary.main, 0.3),
        'line-opacity': isWalk ? 1 : 0,
      },
      layout: {
        'line-cap': 'round' as 'round',
        'line-join': 'round' as 'round',
      },
    }),
    [theme, isWalk]
  );

  /** Primary colour route */
  const routeLayer = useMemo(
    () => ({
      paint: {
        'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 13, 1, 15.5, 5] as Expression,
        'line-color': theme.palette.secondary.main,
        'line-opacity': isWalk ? 1 : 0,
      },
      layout: {
        'line-cap': 'round' as 'round',
        'line-join': 'round' as 'round',
      },
    }),
    [theme, isWalk]
  );

  /** Get data for source */
  const [{ walkRoute }] = useMapData();

  return (
    <>
      <Source id={ROUTE_SOURCE} type="geojson" data={walkRoute}>
        <Layer id={ROUTE_LAYER_CAP} type="line" beforeId={BEFORE_ID} {...routeLayerCap} />
        <Layer id={ROUTE_LAYER} type="line" beforeId={BEFORE_ID} {...routeLayer} />
      </Source>
    </>
  );
};
