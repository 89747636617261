import React, { PureComponent } from 'react';
import { Box, Button } from '@material-ui/core';
import { Title } from '@citydna/common';
import { Link } from 'react-router-dom';

export class CityDnaErrorBoundary extends PureComponent {
  state = {
    error: false,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    return this.state.error ? (
      <Box p={2}>
        <span role="img" aria-label="You've encountered an error" style={{ fontSize: 48 }}>
          😬
        </span>
        <Title
          primary="Oh no, we took you the wrong way!"
          secondary="Sorry about this error, it's our fault."
        />
        <Button component={Link} to="/" variant="contained" color="secondary">
          Start over
        </Button>
      </Box>
    ) : (
      this.props.children
    );
  }
}
