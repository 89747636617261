import React from 'react';
import { Detail } from '../detail';
import { WaypointList } from './WaypointList';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { useMapHeight } from '../common/HeightContext';
import { useWindowHeight } from '@react-hook/window-size';

/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      /** Weird - wouldn't take it in Box height */
      height: (height) => height,
      overflow: 'hidden scroll',
    },
  },
}));

export const DesktopWalk: React.FC = () => {
  const [{ header }] = useMapHeight();
  const windowHeight = useWindowHeight();
  const classes = useStyles(windowHeight - header);

  return (
    <Box className={classes.root}>
      <Detail />
      <WaypointList />
    </Box>
  );
};
