import React, { useEffect } from 'react';
import { Box, useMediaQuery, Theme, makeStyles } from '@material-ui/core';
import { AppHeader } from '@citydna/common';
import ScrollLock from 'react-scrolllock';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import useMeasure from 'react-use-measure';
import { useMapHeight } from './HeightContext';
import { CityDnaErrorBoundary } from './ErrorBoundary';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme: Theme) => ({
  header: {
    '& .MuiAppBar-root': {
      [theme.breakpoints.only('xs')]: {
        borderBottomColor: theme.palette.grey[200] + '!important',
      },
    },
  },
}));

export const Layout: React.FC = ({ children }) => {
  /** Redirect to home if undefined detected in URL. */
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (location.pathname.includes('undefined')) {
      history.push('/');
    }
  });

  /** Track header height */
  const [, setChromeHeight] = useMapHeight();
  const [ref, { height }] = useMeasure();
  useEffect(() => {
    setChromeHeight({ header: height });
  }, [height, setChromeHeight]);

  /** Lock bodyscroll when on walk screen */
  const lock = useRouteMatch('/:slug/walk');
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  /** Get site title */
  const { data } = useQuery(GET_SITE_SETTINGS);

  /** Get CSS classes */
  const classes = useStyles();

  return (
    <>
      <ScrollLock accountForScrollbars={false} isActive={xs && Boolean(lock)}>
        <Box height={1} width={1} display="flex" flexDirection="column">
          <Box position="relative" zIndex={1301}>
            <AppHeader
              title={data?._site.globalSeo.siteName}
              // right={<ToggleTheme />}
              // @ts-ignore
              className={classes.header}
              ref={ref}
            />
          </Box>
          <Box
            display="flex"
            flexGrow={1}
            flexDirection={{ xs: 'column', sm: 'row' }}
            position="relative"
            // @ts-ignore
            // ref={ref}
          >
            <CityDnaErrorBoundary>{children}</CityDnaErrorBoundary>
          </Box>
        </Box>
      </ScrollLock>
    </>
  );
};

export const GET_SITE_SETTINGS = gql`
  query SiteSettings {
    _site {
      globalSeo {
        siteName
        fallbackSeo {
          description
        }
      }
      favicon {
        url
      }
    }
  }
`;
