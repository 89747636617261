import React from 'react';
import { Box, BoxProps, makeStyles, Theme } from '@material-ui/core';
import { Subtitle, Skrim } from '@citydna/common';
import { useWalkScreenController } from './WalkControllerProvider';
import { DirectionalHelper } from './DirectionalHelper';
import { MAX_SHEET_WIDTH } from './WalkSheet';
import { WaypointRecord } from '../__generated__/graphql-types';
import { Picture } from '../common/Picture';

interface WaypointItemProps extends BoxProps {
  waypoint: Partial<WaypointRecord> | undefined | null;
  index?: number;
}

/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: theme.spacing(2),
      cursor: 'pointer',
    },
  },
  image: {
    [theme.breakpoints.only('xs')]: {
      // marginBottom: theme.spacing(1),
      borderTop: '3px solid transparent',
      transition: theme.transitions.create('border-color'),
      '&.active-image': {
        borderTopColor: theme.palette.primary.main,
      },
      '& img': {
        width: '100%',
      },
    },

    [theme.breakpoints.up('sm')]: {
      order: 2,
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '& img': {
        width: 64,
      },
    },
  },
  directionalHelper: {
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      top: theme.spacing(1),
      left: theme.spacing(1),
      right: theme.spacing(1),
      zIndex: 10,
    },
    [theme.breakpoints.up('sm')]: {
      order: 1,
      width: '100%',
      marginBottom: theme.spacing(1),
    },
  },
  subtitle: {
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      bottom: 0,
      padding: theme.spacing(2),
      zIndex: 9,
      '& .MuiTypography-root': {
        color: theme.palette.common.white,
      },
    },
    [theme.breakpoints.up('sm')]: {
      order: 3,
      width: MAX_SHEET_WIDTH - theme.spacing(8) - 64,
    },
  },
  skrim: {
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      zIndex: 8,
      width: '100%',
      height: '50%',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  skrim2: {
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 8,
      width: '100%',
      height: '50%',
      transform: 'rotate(180deg)',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export const WaypointItem: React.FC<WaypointItemProps> = ({ waypoint, index, ...props }) => {
  const classes = useStyles();
  const { activeIndex } = useWalkScreenController();

  return (
    <Box {...props} position="relative" className={classes.root}>
      {waypoint?.pointOfInterest?.photos?.length &&
        waypoint.pointOfInterest.photos[0].responsiveImage && (
          <Picture
            src={waypoint.pointOfInterest.photos[0].responsiveImage.src}
            sources={[
              {
                srcSet: waypoint.pointOfInterest.photos[0].responsiveImage.src + '&w=128&h=128',
                media: '(min-width: 600px)',
              },
              {
                srcSet: waypoint.pointOfInterest.photos[0].responsiveImage.src + '&ar=16:9',
                media: '(max-width: 600px)',
              },
            ]}
            className={`${classes.image} ${index === activeIndex ? 'active-image' : ''}`}
          />
        )}
      <DirectionalHelper
        className={classes.directionalHelper}
        distance={waypoint?.distanceToNextWaypoint}
        helper={waypoint?.directionalHelper}
      />
      <Subtitle
        // @ts-ignore
        primary={index + 1 + '. ' + waypoint.pointOfInterest.name}
        className={classes.subtitle}
      />
      <Skrim className={classes.skrim2} />
      <Skrim className={classes.skrim} />
    </Box>
  );
};
