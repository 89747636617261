import React, { FC, useEffect } from 'react';
import {
  Box,
  Paper,
  Theme,
  makeStyles,
  // useMediaQuery
} from '@material-ui/core';
// import { useRouteMatch } from 'react-router-dom';
// import { animated, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';
import { useMapHeight } from '../common/HeightContext';

export const MAX_SHEET_WIDTH = 384;

/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    border: 'none',
    [theme.breakpoints.only('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderTop:
        '1px solid ' +
        (theme.palette.type === 'dark' ? theme.palette.grey[500] : theme.palette.grey[200]),
    },
    borderRight:
      '1px solid ' + theme.palette.type === 'dark'
        ? theme.palette.grey[500]
        : theme.palette.grey[200],
  },
}));

export const WalkSheet: FC = ({ children }) => {
  const classes = useStyles();

  /** Pass height back up to height context for mobile */
  const [, setChromeHeight] = useMapHeight();
  const [ref, { height }] = useMeasure();
  useEffect(() => {
    setChromeHeight({ sheet: height });
  }, [height, setChromeHeight]);

  return (
    <Box
      // @ts-ignore
      ref={ref}
      order={{ xs: 2, sm: 0 }}
      width={{ xs: '100%', sm: MAX_SHEET_WIDTH }}
      borderRight={{ xs: 'none', sm: '1px solid #ddd' }}
    >
      <Paper elevation={0} className={classes.paper}>
        <div>{children}</div>
      </Paper>
    </Box>
  );
};
