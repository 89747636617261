import React from 'react';
import { Box, Typography, Divider, BoxProps, makeStyles, Theme } from '@material-ui/core';

interface DirectionalHelperProps extends BoxProps {
  distance?: string | null | undefined;
  helper?: string | null | undefined;
}

/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    [theme.breakpoints.only('xs')]: {
      backgroundColor: theme.palette.grey[200],
      opacity: 0.5,
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      position: 'relative',
      zIndex: 8,
      top: -10,
    },
  },
  text: {
    [theme.breakpoints.only('xs')]: {
      color: theme.palette.grey[200],
    },
    [theme.breakpoints.up('sm')]: {
      backgroundColor: theme.palette.common.white,
      position: 'relative',
      zIndex: 9,
      display: 'inline',
      paddingRight: theme.spacing(1),
    },
  },
}));

export const DirectionalHelper: React.FC<DirectionalHelperProps> = ({
  distance,
  helper,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box position="relative" {...props}>
      <Typography variant="body2" className={classes.text}>
        {distance ? `${distance}m, ` : ''}
        {helper}
      </Typography>
      <Divider className={classes.divider} />
    </Box>
  );
};
