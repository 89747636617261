import React, { useMemo } from 'react';
import { Marker } from 'react-map-gl';
import { Typography, makeStyles, Theme, Box, Fab } from '@material-ui/core';

import TimerRoundedIcon from '@material-ui/icons/TimerRounded';
import StraightenRoundedIcon from '@material-ui/icons/StraightenRounded';
import DirectionsRoundedIcon from '@material-ui/icons/DirectionsRounded';
import { Image } from 'react-datocms';
import { point } from '@turf/helpers';
import { useMapData } from '../common/MapDataProvider';
import { useHistory } from 'react-router-dom';

/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 240,
    transform: 'translateX(-120px)',
    background: theme.palette.background.paper,
    border: '1px solid',
    borderColor: theme.palette.grey[200],
  },
  estimates: {
    '& .MuiSvgIcon-root': {
      width: 18,
      height: 18,
      position: 'relative',
      top: 4,
      marginRight: theme.spacing(0.5),
      fill: theme.palette.type === 'dark' ? theme.palette.grey[300] : theme.palette.grey[500],
      '&:nth-child(2)': {
        marginLeft: theme.spacing(1),
      },
    },
  },
  fab: {
    width: 42,
    height: 42,
  },
  fabIcon: {
    color: theme.palette.secondary.main,
  },
}));

export const WalkPopup: React.FC = () => {
  const [{ walkPinPopup }, dispatch] = useMapData();

  const hours =
    walkPinPopup?.properties?.estimatedTime &&
    parseFloat((walkPinPopup?.properties.estimatedTime / 60).toFixed(1));
  const distance =
    walkPinPopup?.properties?.distance &&
    parseFloat((walkPinPopup?.properties.distance / 1000).toFixed(1));

  const previewImage = walkPinPopup?.properties?.previewImage;
  const image = useMemo(() => {
    if (previewImage) {
      const imageData = JSON.parse(previewImage);
      return <Image data={imageData.responsiveImage} />;
    }

    return null;
  }, [previewImage]);

  /**
   * On enter the marker box, set the pinHover state with popup: true to persist popup when mouse exits original pin
   */
  const handleEnter = () => {
    if (!walkPinPopup) return;
    dispatch({
      walkPinPopup: point(walkPinPopup.geometry.coordinates, {
        ...walkPinPopup.properties,
        popup: true,
      }),
    });
  };

  /**
   * Clear pinHover state on exit.
   */
  const handleLeave = () => {
    dispatch({ walkPinPopup: undefined });
  };

  /** Handle FAB click */
  const history = useHistory();
  const handleFabClick = () => {
    handleLeave();
    history.push(`/${walkPinPopup?.properties?.slug}/walk/preview`);
  };

  const classes = useStyles();
  return walkPinPopup ? (
    <Marker
      longitude={walkPinPopup.geometry.coordinates[0]}
      latitude={walkPinPopup.geometry.coordinates[1]}
    >
      <Box className={classes.root} onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
        {image}
        <Box p={2}>
          <Box display="flex">
            <Box>
              <Typography variant="h3">{walkPinPopup.properties?.name}</Typography>
              <Typography className={classes.estimates} variant="body2">
                <TimerRoundedIcon />
                {hours && `${hours} hour${hours > 1 ? 's' : ''}`}
                <StraightenRoundedIcon />
                {distance && `${distance}km`}
              </Typography>
            </Box>
            <Box width={42} ml="auto">
              <Fab size="small" className={classes.fab} onClick={handleFabClick}>
                <DirectionsRoundedIcon className={classes.fabIcon} />
              </Fab>
            </Box>
          </Box>
        </Box>
      </Box>
    </Marker>
  ) : null;
};
