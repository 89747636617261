import React from 'react';
import { useMapData } from '../common/MapDataProvider';
import { WaypointItem } from './WaypointItem';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Typography, makeStyles, Theme } from '@material-ui/core';
import { PointOfInterestRecord } from '../__generated__/graphql-types';

/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTop: '1px solid',
    borderTopColor:
      theme.palette.type === 'dark' ? theme.palette.grey[500] : theme.palette.grey[200],
  },
  subtitle: {
    fontSize: 14.22,
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
}));

export const WaypointList = () => {
  const [{ walkData }] = useMapData();

  /** Handle item click */
  const { slug } = useParams();
  const history = useHistory();
  const handleClick = (waypoint: Partial<PointOfInterestRecord> | null | undefined) =>
    waypoint && history.push(`/${slug}/walk/${waypoint.slug}/detail`);

  const firstStop = walkData?.waypoints?.length && walkData.waypoints[0]?.pointOfInterest?.name;

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="subtitle2" className={classes.subtitle}>
        {walkData?.waypoints?.length} stops, starting at {firstStop}
      </Typography>
      {walkData?.waypoints?.map((waypoint, i) => (
        <WaypointItem
          // @ts-ignore
          key={waypoint.id}
          onClick={() => handleClick(waypoint?.pointOfInterest)}
          index={i}
          // @ts-ignore
          waypoint={waypoint}
        />
      ))}
    </Box>
  );
};
