import React from 'react';
import { Theme, SnackbarProvider } from '@citydna/common';
import { DatoCMSProvider } from './@citydna/datocms';
import { MapProvider } from '@citydna/maps';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// route components
import { Home, WalkingMapsList } from './home';
import { Walk } from './walk';
import { WalkControllerProvider } from './walk/WalkControllerProvider';
import { Layout } from './common/Layout';
import { Map } from './common/Map';
import { MapHeightProvider } from './common/HeightContext';
import { MapDataProvider } from './common/MapDataProvider';

function App() {
  return (
    <DatoCMSProvider token={process.env.REACT_APP_DATO_CMS_TOKEN}>
      <Theme>
        <SnackbarProvider>
          <MapProvider>
            <Router>
              <MapDataProvider>
                <WalkControllerProvider>
                  <MapHeightProvider>
                    <Layout>
                      <Switch>
                        <Route path="/:slug/walk/:poiSlug?/:detail?">
                          <Walk />
                        </Route>
                        {/* <Route path="/:slug">
                          <Detail />
                        </Route> */}
                        <Route path="/">
                          <Home>
                            <WalkingMapsList />
                          </Home>
                        </Route>
                      </Switch>
                      <Map />
                    </Layout>
                  </MapHeightProvider>
                </WalkControllerProvider>
              </MapDataProvider>
            </Router>
          </MapProvider>
        </SnackbarProvider>
      </Theme>
    </DatoCMSProvider>
  );
}

export default App;
